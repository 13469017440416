import { Sentiment } from './types';

interface Params {
  price: number;
  lastPrice: number;
  isSignatureEtfTicker: boolean;
}

const getSignaturePrintSentiment = ({ price, lastPrice, isSignatureEtfTicker }: Params) => {
  if (price === lastPrice || !isSignatureEtfTicker) {
    return Sentiment.None;
  }

  if (price < lastPrice) {
    return Sentiment.Bullish;
  } else {
    return Sentiment.Bearish;
  }
};

export default getSignaturePrintSentiment;
