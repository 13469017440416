import { HIGH_VOLUME_TRADE_PERCENT } from './types';

export const getIsGoldenDarkPool = (
  size: number | null | undefined,
  avgVolume: number | null | undefined,
) => {
  if (size === 0 || avgVolume === 0) {
    return false;
  }

  return (size || 1) / (avgVolume || 1) >= HIGH_VOLUME_TRADE_PERCENT;
};
