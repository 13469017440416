import * as React from 'react';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import Card from 'components/Card';
import PutCallDonutChart from './PutCallDonutChart';

interface Props {
  className?: string;
  title?: string;
  putFlow?: number;
  putVolume?: number;
  callVolume?: number;
  tradeCount?: number;
}

const PutCallCard: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title = 'Put / Call Flow',
  putFlow = 0,
  tradeCount = 0,
  putVolume = 0,
  callVolume = 0,
}) => {
  const puts = Math.round(putFlow * 100);
  const calls = Math.round((1 - putFlow) * 100);

  return (
    <Card className={`flex w-full items-center justify-between md:w-1/3 ${className || ''}`}>
      <div>
        <div className="mb-1 text-xs font-semibold uppercase text-gray-500">{title}</div>
        <div className="mb-1 text-xs text-blue-200">{abbreviateNumber(putVolume)} Puts</div>
        <div className="text-xs text-blue-200">{abbreviateNumber(callVolume)} Calls</div>
      </div>
      {!!tradeCount && <PutCallDonutChart puts={puts} calls={calls} />}
    </Card>
  );
};

export default PutCallCard;
