export enum SignalAsset {
  Stock = 'STOCK',
  Crypto = 'CRYPTO',
  Forex = 'FOREX',
}

export enum SignalType {
  TrendChange = 'TREND_CHANGE',
  LongPosition = 'LONG_POSITION',
  ShortPosition = 'SHORT_POSITION',
}

export interface SignalPayload {
  text: string;
  asset: SignalAsset;
}

export const signalTypeText: Record<string, SignalType> = {
  'Trend Change': SignalType.TrendChange,
  'Long Signal': SignalType.LongPosition,
  'Short Signal': SignalType.ShortPosition,
};
