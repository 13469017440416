import * as React from 'react';
import useAppendScript from 'hooks/useAppendScript';
import { TV_SYMBOL_INFO_SRC, TV_SYMBOL_INFO_PROPS } from 'constants/tradingview';

const SCRIPT_SRC = TV_SYMBOL_INFO_SRC;
const WIDGET_PROPS = { ...TV_SYMBOL_INFO_PROPS };
const NODE_ID = 'tv-symbol-info';

const SymbolInfo: React.FC<React.PropsWithChildren<{ activeTicker: string; path: string }>> = ({ path, activeTicker }) => {
  const largeChartUrl = `${process.env.ROOT_URL}${path}?ticker=${activeTicker}&tab=screener`;
  const code = JSON.stringify({ ...WIDGET_PROPS, symbol: activeTicker, largeChartUrl });

  useAppendScript({
    src: SCRIPT_SRC,
    nodeId: NODE_ID,
    code,
  });

  return <div id={NODE_ID}></div>;
};

export default SymbolInfo;
