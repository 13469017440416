const MS_TO_DAYS = 1000 * 3600 * 24;

interface ExpiryFields {
  daysToExpiry?: number;
  expireYear?: number | null;
  expireMonth?: number | null;
  expireDay?: number | null;
  date?: string;
}

const getDaysToExpiry = (trade: ExpiryFields) => {
  if (trade.daysToExpiry) {
    return trade.daysToExpiry;
  }

  const todaysTime = new Date(trade.date || '').getTime();
  const date = new Date();
  const year = trade.expireYear || date.getFullYear();
  const month = (trade.expireMonth || 1) - 1 || date.getMonth();
  const day = trade.expireDay || date.getDay();
  const expiryTime = new Date(year, month, day).getTime();
  const differenceInDays = (expiryTime - todaysTime) / MS_TO_DAYS;

  return differenceInDays;
};

export default getDaysToExpiry;
