import * as React from 'react';
import Chart from 'chart.js';
import { themeDark } from 'styles/theme';
import { BULLISH, SLIGHTLY_BULLISH, SLIGHTLY_BEARISH, BEARISH } from 'utils/shared/trades/types';

import { SentimentText } from './styles';

interface Props {
  puts: number;
  calls: number;
}

const PutFlowText: React.FC<React.PropsWithChildren<{ putFlow: number }>> = ({ putFlow }) => {
  if (putFlow <= BULLISH) {
    return <div>Bullish</div>;
  } else if (putFlow > BULLISH && putFlow < SLIGHTLY_BULLISH) {
    return (
      <div>
        <div>Slightly</div>
        <div>Bullish</div>
      </div>
    );
  } else if (putFlow >= SLIGHTLY_BULLISH && putFlow <= SLIGHTLY_BEARISH) {
    return (
      <div>
        <div>Mixed</div>
      </div>
    );
  } else if (putFlow > SLIGHTLY_BEARISH && putFlow <= BEARISH) {
    return (
      <div>
        <div>Slightly</div>
        <div>Bearish</div>
      </div>
    );
  } else if (putFlow > BEARISH) {
    return <div>Bearish</div>;
  }

  return <div />;
};

const PurCallDonutChart: React.FC<React.PropsWithChildren<Props>> = ({ puts, calls }) => {
  const chartWrapperRef = React.useRef<HTMLCanvasElement>(null);
  const chartRef = React.useRef<Chart | undefined>(undefined);

  React.useEffect(() => {
    const myChartRef = chartRef.current;
    if (myChartRef?.data.datasets) {
      myChartRef.data.datasets[0].data = [puts, calls];
      myChartRef.update();
    }
  }, [puts, calls]);

  React.useEffect(() => {
    const myChartRef = chartWrapperRef.current?.getContext('2d');

    if (myChartRef) {
      chartRef.current = new Chart(myChartRef, {
        type: 'doughnut',
        data: {
          labels: ['Puts', 'Calls'],
          datasets: [
            {
              data: [puts, calls],
              backgroundColor: [themeDark.color.graphPut, themeDark.color.graphCall],
              borderWidth: 0,
            },
          ],
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          circumference: Math.PI,
          rotation: -Math.PI,
          tooltips: {
            callbacks: {
              label: function (item, data) {
                // @ts-ignore
                const label = data.labels[item.index];
                // @ts-ignore
                const flow = data.datasets[0].data[item.index];
                return `${label}: ${Math.round(flow)}%`;
              },
            },
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, []);

  return (
    <div className="relative h-full" style={{ maxHeight: '64px', height: '100%' }}>
      <canvas ref={chartWrapperRef} style={{ height: '64px', zIndex: 1, position: 'relative' }} />
      <SentimentText className="absolute text-center text-xs font-semibold uppercase">
        <PutFlowText putFlow={puts} />
      </SentimentText>
    </div>
  );
};

export default PurCallDonutChart;
