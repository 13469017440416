import * as React from 'react';
import Link from 'components/Link';
import { ApolloError } from '@apollo/client';
import { Switch } from '@headlessui/react';
import { RiSignalTowerFill } from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
import { ImArrowUpRight, ImArrowDownRight, ImShuffle } from 'react-icons/im';
import PulseLoader from 'components/Loading/Pulse';
import SectionTitle from 'components/SectionTitle';
import { useCurrentUser } from 'hooks/useCurrentUser';
import convertTimestampToTime from 'utils/shared/time/convertTimestampToTime';
import { SignalAsset } from 'utils/shared/signals/constants';
import PermissionedBlock from 'components/PermissionedBlock';
import { hasDashboardAccess } from 'services/client/token';
import { AuthStatus } from 'constants/auth';
import capsToTitleCase from 'utils/shared/strings/capsToTitleCase';
import { TICKER_FLOW_PAGE, CRYPTO_PAGE, FOREX_PAGE } from 'constants/pages';
import {
  TableWrapper,
  TableCell,
  TableHeader,
  TableBody,
  HeaderCell,
  MutedText,
  HeaderCellWrapper,
  WatchListIcon,
} from 'components/Table';
import {
  Timezone_Location_Enum,
  useGetAllAssetAlertSignalsLazyQuery,
  useGetAssetAlertSignalsGroupedByAssetLazyQuery,
  useGetAssetAlertSignalsForAssetClassLazyQuery,
  useGetAssetAlertSignalsByTickerLazyQuery,
  GetAllAssetAlertSignalsQuery,
  GetAssetAlertSignalsGroupedByAssetQuery,
  GetAssetAlertSignalsForAssetClassQuery,
  GetAssetAlertSignalsByTickerQuery,
} from 'types/generated/client';
import { Props } from './props';

import { TableRow } from './styles';

const POLL_INTERVAL = 5 * 60000;
const TITLE_NAMES = ['Date', 'Symbol', 'Signal', 'Price'];

type DataType =
  | GetAllAssetAlertSignalsQuery
  | GetAssetAlertSignalsGroupedByAssetQuery
  | GetAssetAlertSignalsForAssetClassQuery
  | GetAssetAlertSignalsByTickerQuery;

const mapAssetToPage = (asset: SignalAsset, ticker: string) => {
  if (asset === SignalAsset.Stock) {
    return `${TICKER_FLOW_PAGE}/${ticker}`;
  } else if (asset === SignalAsset.Crypto) {
    return `${CRYPTO_PAGE}?ticker=${ticker}&tab=screener`;
  } else if (asset === SignalAsset.Forex) {
    return `${FOREX_PAGE}?ticker=${ticker}&tab=screener`;
  }

  return `${TICKER_FLOW_PAGE}/${ticker}`;
};

const SignalCell: React.FC<React.PropsWithChildren<{ signal: string }>> = ({ signal }) => {
  if (signal === 'TREND_CHANGE') {
    return (
      <span className="flex items-center">
        <ImShuffle className="mr-3 text-yellow-400" /> Trend Change
      </span>
    );
  } else if (signal === 'LONG_POSITION') {
    return (
      <span className="flex items-center">
        <ImArrowUpRight className="mr-3 text-green-400" /> Long
      </span>
    );
  } else if (signal === 'SHORT_POSITION') {
    return (
      <span className="flex items-center">
        <ImArrowDownRight className="mr-3 text-red-400" /> Short
      </span>
    );
  }

  return <div />;
};

const SignalAlertTable: React.FC<React.PropsWithChildren<Props>> = ({
  asset,
  ticker,
  title,
  watchListTickers,
  viewer,
  searchValue,
  openInNewTab,
  hideWatchList,
}) => {
  const { user: currentUser } = useCurrentUser();
  const timezoneLocation = currentUser?.timezoneLocation || Timezone_Location_Enum.Current;
  const isWallstreetTimezone = timezoneLocation === Timezone_Location_Enum.Wallstreet;

  const isLoadingAuthStatus = viewer.status === AuthStatus.Loading;
  const hasAccess = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
  const [isWatchListOnly, setIsWatchListOnly] = React.useState(false);
  const [shouldIncludeStocks, setShouldIncludeStocks] = React.useState(false);
  const [shouldIncludeCrypto, setShouldIncludeCrypto] = React.useState(false);
  const [shouldIncludeForex, setShouldIncludeForex] = React.useState(false);

  const groupedAssets = [];

  if (shouldIncludeStocks) {
    groupedAssets.push({ asset: { _eq: SignalAsset.Stock } });
  }
  if (shouldIncludeCrypto) {
    groupedAssets.push({ asset: { _eq: SignalAsset.Crypto } });
  }
  if (shouldIncludeForex) {
    groupedAssets.push({ asset: { _eq: SignalAsset.Forex } });
  }

  const [
    fetchAllSignals,
    {
      data: allSignalsData,
      loading: allSignalsLoading,
      error: allSignalsError,
      refetch: allSignalsRefetch,
    },
  ] = useGetAllAssetAlertSignalsLazyQuery();
  const [
    fetchGroupSignals,
    {
      data: groupSignalsData,
      loading: groupSignalsLoading,
      error: groupSignalsError,
      refetch: groupRefetch,
    },
  ] = useGetAssetAlertSignalsGroupedByAssetLazyQuery({ variables: { assets: groupedAssets } });
  const [
    fetchAssetSignals,
    {
      data: assetSignalsData,
      loading: assetSignalsLoading,
      error: assetSignalsError,
      refetch: assetSignalsRefetch,
    },
  ] = useGetAssetAlertSignalsForAssetClassLazyQuery({ variables: { asset: asset || '' } });
  const [
    fetchTickerSignals,
    {
      data: tickerSignalsData,
      loading: tickerSignalsLoading,
      error: tickerSignalsError,
      refetch: tickerSignalsRefetch,
    },
  ] = useGetAssetAlertSignalsByTickerLazyQuery({
    variables: { ticker: ticker || '', asset: asset || '' },
  });
  const shouldIncludeControls = !asset && !ticker;
  const shouldQueryForAll =
    !shouldIncludeStocks && !shouldIncludeCrypto && !shouldIncludeForex && shouldIncludeControls;
  const shouldGroupByAssets =
    (shouldIncludeStocks || shouldIncludeCrypto || shouldIncludeForex) && shouldIncludeControls;
  const shouldQueryForTicker =
    !!ticker && isWatchListOnly && !shouldQueryForAll && !shouldGroupByAssets;
  const shouldQueryForAsset =
    !!asset && !shouldQueryForAll && !shouldGroupByAssets && !shouldQueryForTicker;
  let loading: boolean | undefined;
  let error: ApolloError | undefined;
  let data: DataType | undefined;

  if (shouldQueryForAll) {
    data = allSignalsData;
    loading = allSignalsLoading;
    error = allSignalsError;
  } else if (shouldGroupByAssets) {
    data = groupSignalsData;
    loading = groupSignalsLoading;
    error = groupSignalsError;
  } else if (shouldQueryForTicker) {
    data = tickerSignalsData;
    loading = tickerSignalsLoading;
    error = tickerSignalsError;
  } else if (shouldQueryForAsset) {
    data = assetSignalsData;
    loading = assetSignalsLoading;
    error = assetSignalsError;
  } else {
    data = allSignalsData;
    loading = allSignalsLoading;
    error = allSignalsError;
  }

  const signals = data?.asset_signal_alerts || [];
  const showLoading = signals.length === 0 && loading;
  const filteredSignals = signals.filter((item) => {
    const passesWatchListTest = isWatchListOnly
      ? watchListTickers.includes(item.ticker || '')
      : true;
    const passesSearchTest = searchValue ? item.ticker?.includes(searchValue) : true;
    return passesWatchListTest && passesSearchTest;
  });

  // TODO: Instead of checking on an interval, check at the top of the hour since they at most come once per hour

  // ALL SIGNALS
  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess && shouldQueryForAll && fetchAllSignals) {
      if (allSignalsRefetch) {
        allSignalsRefetch();
      } else {
        fetchAllSignals();
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (allSignalsRefetch) {
          allSignalsRefetch();
        } else if (fetchAllSignals) {
          fetchAllSignals();
        }
      }, POLL_INTERVAL);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [hasAccess, shouldQueryForAll, fetchAllSignals, allSignalsRefetch]);
  // GROUPED BY ASSETS
  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess && shouldGroupByAssets && fetchGroupSignals) {
      const groupedAssets = [];

      if (shouldIncludeStocks) {
        groupedAssets.push({ asset: { _eq: SignalAsset.Stock } });
      }
      if (shouldIncludeCrypto) {
        groupedAssets.push({ asset: { _eq: SignalAsset.Crypto } });
      }
      if (shouldIncludeForex) {
        groupedAssets.push({ asset: { _eq: SignalAsset.Forex } });
      }

      const variables = { assets: groupedAssets };

      if (groupRefetch) {
        groupRefetch(variables);
      } else {
        fetchGroupSignals({ variables });
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (groupRefetch) {
          groupRefetch(variables);
        } else if (fetchGroupSignals) {
          fetchGroupSignals({ variables });
        }
      }, POLL_INTERVAL);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    hasAccess,
    shouldGroupByAssets,
    fetchGroupSignals,
    groupRefetch,
    shouldIncludeStocks,
    shouldIncludeCrypto,
    shouldIncludeForex,
  ]);
  // BY TICKER
  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess && shouldQueryForTicker && fetchTickerSignals) {
      const variables = { ticker: ticker || '', asset: (asset as string) || '' };

      if (tickerSignalsRefetch) {
        tickerSignalsRefetch(variables);
      } else {
        fetchTickerSignals({ variables });
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (tickerSignalsRefetch) {
          tickerSignalsRefetch(variables);
        } else if (fetchTickerSignals) {
          fetchTickerSignals({ variables });
        }
      }, POLL_INTERVAL);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [hasAccess, shouldQueryForTicker, fetchTickerSignals, tickerSignalsRefetch, ticker, asset]);
  // SINGLE ASSET
  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess && shouldQueryForAsset && fetchAssetSignals) {
      const variables = { asset: asset as string };

      if (assetSignalsRefetch) {
        assetSignalsRefetch(variables);
      } else {
        fetchAssetSignals({ variables });
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (assetSignalsRefetch) {
          assetSignalsRefetch(variables);
        } else if (fetchAssetSignals) {
          fetchAssetSignals({ variables });
        }
      }, POLL_INTERVAL);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [hasAccess, shouldQueryForAsset, fetchAssetSignals, assetSignalsRefetch, asset]);

  if (error) {
    // TODO: Figure out if I want to show errors
  }

  return (
    <TableWrapper>
      <TableHeader>
        <div className="mb-2 flex justify-between">
          <div className="flex">
            <SectionTitle
              Icon={RiSignalTowerFill}
              title={title || 'Signals'}
              style={{ marginBottom: 0 }}
            />
            {!hideWatchList && (
              <Switch.Group as="div" className="ml-4 flex items-center">
                <Switch
                  checked={isWatchListOnly}
                  onChange={setIsWatchListOnly}
                  className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'bg-yellow-400' : 'bg-gray-600'}
                      pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
                    `}
                  />
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'translate-x-3' : 'translate-x-0'} ${
                      isWatchListOnly ? 'bg-white' : 'bg-gray-300'
                    } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out`}
                  />
                </Switch>
                <div
                  className={`ml-2 text-xs ${
                    isWatchListOnly ? 'text-yellow-400' : 'text-gray-600'
                  }`}
                >
                  <AiFillStar />
                </div>
              </Switch.Group>
            )}
          </div>
          {shouldIncludeControls && (
            <div className="flex items-center">
              <div className="ml-4 flex items-center">
                <input
                  style={{ width: '12px', height: '12px' }}
                  aria-describedby="stock-signals"
                  id="stock-signals"
                  name="stock-signals"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={shouldIncludeStocks}
                  onChange={() => {
                    setShouldIncludeStocks(!shouldIncludeStocks);
                  }}
                />
                <label
                  htmlFor="stock-signals"
                  className="ml-1 font-semibold text-gray-200"
                  style={{ fontSize: '14px' }}
                >
                  S
                </label>
              </div>
              <div className="ml-4 flex items-center">
                <input
                  style={{ width: '12px', height: '12px' }}
                  aria-describedby="crypto-signals"
                  id="crypto-signals"
                  name="crypto-signals"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={shouldIncludeCrypto}
                  onChange={() => {
                    setShouldIncludeCrypto(!shouldIncludeCrypto);
                  }}
                />
                <label
                  htmlFor="crypto-signals"
                  className="ml-1 font-semibold text-gray-200"
                  style={{ fontSize: '14px' }}
                >
                  C
                </label>
              </div>
              <div className="ml-4 flex items-center">
                <input
                  style={{ width: '12px', height: '12px' }}
                  aria-describedby="forex-signals"
                  id="forex-signals"
                  name="forex-signals"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={shouldIncludeForex}
                  onChange={() => {
                    setShouldIncludeForex(!shouldIncludeForex);
                  }}
                />
                <label
                  htmlFor="forex-signals"
                  className="ml-1 font-semibold text-gray-200"
                  style={{ fontSize: '14px' }}
                >
                  F
                </label>
              </div>
            </div>
          )}
        </div>
        <TableRow removePad>
          {TITLE_NAMES.map((t, i) => {
            return (
              <HeaderCellWrapper key={t} removePad={i + 1 === TITLE_NAMES.length}>
                <HeaderCell>
                  <span className="cell-name">{t}</span>
                </HeaderCell>
              </HeaderCellWrapper>
            );
          })}
        </TableRow>
      </TableHeader>
      <TableBody animateRows>
        {showLoading || isLoadingAuthStatus ? (
          <div className="flex h-full w-full items-center justify-center">
            <PulseLoader />
          </div>
        ) : !hasAccess ? (
          <PermissionedBlock />
        ) : (
          filteredSignals.map((signal) => {
            const isWatchListTrade =
              !!signal.ticker && watchListTickers.includes(signal.ticker) && shouldIncludeControls;
            const { time, timeSubscript, date } = convertTimestampToTime({
              timestamp: signal.createdAt,
              isWallstreetTimezone,
            });
            const ticker =
              signal.asset === SignalAsset.Crypto
                ? signal.ticker.replace('USD', '')
                : signal.ticker;
            return (
              <TableRow key={signal.id} tableBodyRow isAnimated>
                <TableCell>
                  <MutedText>
                    <div>{date}</div>
                    <div>
                      {time} {timeSubscript}
                    </div>
                  </MutedText>
                </TableCell>
                <TableCell className="relative">
                  {isWatchListTrade && (
                    <WatchListIcon className="absolute text-yellow-400">
                      <AiFillStar />
                    </WatchListIcon>
                  )}
                  <Link
                    href={mapAssetToPage(signal.asset as SignalAsset, ticker)}
                    target={openInNewTab ? '_blank' : undefined}
                  >
                    <span>{ticker}</span>
                  </Link>
                  <span className="ml-2 text-gray-500">{capsToTitleCase(signal.asset)}</span>
                </TableCell>
                <TableCell>
                  <SignalCell signal={signal.signal} />
                </TableCell>
                <TableCell>${signal.price}</TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </TableWrapper>
  );
};

export default SignalAlertTable;
