const sizeToMomentum = (size: number = 0) => {
  if (!size) {
    return 0;
  }

  let convertedSize = size;
  while (convertedSize > 1) {
    convertedSize = convertedSize / 10;
  }

  return convertedSize;
};

export default sizeToMomentum;
