import styled from 'styled-components';
import { rgba } from 'styles/utils/color';

const STRENGTH_METER = 10;

export const AggregateRow = styled.div<{ tableBodyRow?: boolean; strength?: number }>`
  border-bottom: ${({ tableBodyRow, theme }) =>
    tableBodyRow ? `1px solid ${theme.color.border}` : 'none'};
  align-items: ${({ tableBodyRow }) => (tableBodyRow ? 'center' : 'end')};
  display: grid;
  grid-template-columns: repeat(5, minmax(64px, 1fr));
  height: ${({ tableBodyRow }) => (tableBodyRow ? '44px' : 'auto')};
  background: transparent;
`;

export const RowBackground = styled.div<{ strength: number }>`
  width: ${({ strength }) => strength}%;
  background: ${({ strength, theme }) =>
    rgba(theme.color.darkpool, Math.min(strength + STRENGTH_METER, 100) / 100)};
`;
