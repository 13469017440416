import * as React from 'react';
import AriaModal, { AriaModalProps, RequiredAriaTypes } from 'react-aria-modal';
import { MAX_CONTENT_WIDTH } from 'styles/elements';
import CloseButton from './CloseButton';

import { ModalWrapper } from './styles';

export enum ModalWidths {
  Skinny = 'skinny',
  Medium = 'medium',
  Content = 'content',
  ContentLarge = 'contentlarge',
  CoverPage = 'coverPage',
  Page = 'page',
}

export const maxModalWidths = {
  [ModalWidths.Skinny]: '456px',
  [ModalWidths.Medium]: '560px',
  [ModalWidths.Content]: `${MAX_CONTENT_WIDTH}px`,
  [ModalWidths.ContentLarge]: `896px`,
  [ModalWidths.CoverPage]: `96vw`,
  [ModalWidths.Page]: `100vw`,
};

interface Props {
  useCloseButton?: boolean;
  width?: ModalWidths;
  border?: boolean;
  dark?: boolean;
  maxHeight?: string;
}

const Modal: React.FC<React.PropsWithChildren<Props & AriaModalProps & RequiredAriaTypes>> = ({
  useCloseButton = true,
  width = ModalWidths.Skinny,
  dark,
  border,
  children,
  ...props
}) => {
  if (typeof window === 'undefined' || !props.mounted) {
    return null;
  }

  const maxModalWidth = maxModalWidths[width] || maxModalWidths.skinny;

  return (
    <AriaModal underlayColor="rgba(0,0,0,0.85)" {...props}>
      <ModalWrapper
        className="rounded"
        width={maxModalWidth}
        maxHeight={props.maxHeight}
        dark={dark}
        border={border}
      >
        {children}
        {useCloseButton && <CloseButton onExit={props.onExit} />}
      </ModalWrapper>
    </AriaModal>
  );
};

export default Modal;
