import * as React from 'react';
import { BsBarChartSteps } from 'react-icons/bs';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { SortDirection } from 'utils/shared/trades/types';
import SectionTitle from 'components/SectionTitle';
import {
  TableWrapper,
  TableCell,
  TableHeader,
  TableBody,
  HeaderCellWrapper,
  HeaderCell,
  HeaderIcon,
  IconSortDesc,
  IconSortAsc,
} from 'components/Table';
import PulseLoader from 'components/Loading/Pulse';
import { useGetDarkPoolLevelsQuery, GetDarkPoolLevelsQuery } from 'types/generated/client';
import { LevelProps, LevelsHeaderProps, LEVELS_TABLE_HEADERS, DEFAULT_LEVELS_KEY } from './props';

import { AggregateRow, RowBackground } from './styles';

const Header: React.FC<React.PropsWithChildren<LevelsHeaderProps>> = React.memo(
  ({
    aggregateSortKey,
    setAggregateSortKey,
    aggregateSortDirection,
    setAggregateSortDirection,
    ticker,
  }) => {
    return (
      <TableHeader>
        <div className="mb-2">
          <SectionTitle
            Icon={BsBarChartSteps}
            title={`${ticker} Dark Pool Levels`}
            style={{ marginBottom: 0 }}
          />
        </div>
        <AggregateRow style={{ paddingRight: '4px' }}>
          {LEVELS_TABLE_HEADERS.map((t) => {
            const isActiveSort =
              t.sortKey === aggregateSortKey && aggregateSortDirection !== SortDirection.None;
            return (
              <HeaderCellWrapper
                key={t.name}
                isActiveSort={isActiveSort}
                onClick={() => {
                  if (aggregateSortKey !== t.sortKey) {
                    setAggregateSortKey(t.sortKey);
                    setAggregateSortDirection(SortDirection.Desc);
                  } else if (
                    aggregateSortKey === t.sortKey &&
                    aggregateSortDirection === SortDirection.Desc
                  ) {
                    setAggregateSortDirection(SortDirection.Asc);
                  } else if (
                    aggregateSortKey === t.sortKey &&
                    aggregateSortDirection === SortDirection.Asc
                  ) {
                    setAggregateSortKey(DEFAULT_LEVELS_KEY);
                    setAggregateSortDirection(SortDirection.None);
                  }
                }}
              >
                <HeaderCell>
                  <span className="cell-name">{t.name}</span>
                  {isActiveSort && aggregateSortDirection === SortDirection.Desc && (
                    <HeaderIcon>
                      <IconSortDesc />
                    </HeaderIcon>
                  )}
                  {isActiveSort && aggregateSortDirection === SortDirection.Asc && (
                    <HeaderIcon>
                      <IconSortAsc />
                    </HeaderIcon>
                  )}
                </HeaderCell>
              </HeaderCellWrapper>
            );
          })}
        </AggregateRow>
      </TableHeader>
    );
  },
);

const Row: React.FC<React.PropsWithChildren<{
  item: GetDarkPoolLevelsQuery['dark_pool_levels'][0] & { strength: number };
  maxLevel: number;
}>> = ({ item }) => {
  return (
    <div className="relative w-full">
      <RowBackground className="absolute left-0 top-0 h-full" strength={item.strength} />
      <AggregateRow
        key={item.price}
        strength={item.strength}
        tableBodyRow
        className="relative px-4"
      >
        <TableCell>{item.price}</TableCell>
        <TableCell>{item.prints}</TableCell>
        <TableCell>{abbreviateNumber(item.volume)}</TableCell>
        <TableCell>${abbreviateNumber(item.amount)}</TableCell>
        <TableCell>{Math.round(item.strength)}</TableCell>
      </AggregateRow>
    </div>
  );
};

const DarkPoolLevelsTable: React.FC<React.PropsWithChildren<LevelProps>> = ({ ticker }) => {
  const [aggregateSortKey, setAggregateSortKey] = React.useState(DEFAULT_LEVELS_KEY);
  const [aggregateSortDirection, setAggregateSortDirection] = React.useState(SortDirection.None);
  const { data, loading } = useGetDarkPoolLevelsQuery({ variables: { ticker } });
  const levels = data?.dark_pool_levels?.length ? data?.dark_pool_levels : [];

  const groupedLevels = React.useMemo(() => {
    const groupedByLevel: Record<string, GetDarkPoolLevelsQuery['dark_pool_levels'][0]> = {};

    levels.forEach((level) => {
      const price = level.price.toFixed(2);
      if (!groupedByLevel[price]) {
        groupedByLevel[price] = {
          ticker: level.ticker,
          amount: 0,
          prints: 0,
          price: price,
          volume: 0,
        };
      }
      groupedByLevel[price].amount += level.amount;
      groupedByLevel[price].prints += level.prints;
      groupedByLevel[price].volume += level.volume;
    });

    return Object.values(groupedByLevel);
  }, [levels, aggregateSortKey, aggregateSortDirection]);
  const maxLevel = React.useMemo(() => {
    let max = 1;

    for (let i = 0; i < groupedLevels.length; i++) {
      if (groupedLevels[i].volume > max) {
        max = groupedLevels[i].volume;
      }
    }

    return max;
  }, [groupedLevels]);
  const levelsWithStrength = React.useMemo(() => {
    return groupedLevels.map((level) => ({
      ...level,
      strength: ((level.volume || 0) / maxLevel) * 100,
    }));
  }, [maxLevel, groupedLevels]);
  const displayLevels = React.useMemo(() => {
    const sortedlevels = levelsWithStrength.sort((a, b) => {
      let first = aggregateSortDirection === SortDirection.Asc ? a : b;
      let last = aggregateSortDirection === SortDirection.Asc ? b : a;

      // @ts-ignore
      if (first[aggregateSortKey] < last[aggregateSortKey]) {
        return -1;
      }
      // @ts-ignore
      if (first[aggregateSortKey] > last[aggregateSortKey]) {
        return 1;
      }
      return 0;
    });

    return sortedlevels;
  }, [levelsWithStrength]);

  return (
    <TableWrapper>
      <Header
        ticker={ticker}
        aggregateSortKey={aggregateSortKey}
        setAggregateSortKey={setAggregateSortKey}
        aggregateSortDirection={aggregateSortDirection}
        setAggregateSortDirection={setAggregateSortDirection}
      />
      <TableBody>
        {loading ? (
          <div className="flex h-full items-center justify-center">
            <PulseLoader />
          </div>
        ) : displayLevels?.length ? (
          displayLevels.map((item) => {
            return <Row key={item.price} item={item} maxLevel={maxLevel} />;
          })
        ) : (
          <div className="flex h-full items-center justify-center text-2xl text-gray-400">
            No levels around current price
          </div>
        )}
      </TableBody>
    </TableWrapper>
  );
};

export default React.memo(DarkPoolLevelsTable);
