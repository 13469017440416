import sizeToMomentum from 'utils/shared/trades/sizeToMomentum';
import { Trade, DAYS_PER_YEAR } from './types';

// NOTE: This is a sane minimum since some trades can be very sparsely traded
const MIN_SIZE_PER_DAY = 100;
const MIN_TRADES_PER_DAY = 3;
const LIMIT_SMALL_TRADE_SPIKE = 10;
const MAX_EXTRAORDINARY_MOMENTUM = 40;
const MIN_TRADES_TODAY = 2;

const calculateTickerOverview = (trades?: Trade[], dailyVolume: number = 0) => {
  let tickerOverview = {
    otm: 0,
    expiry: 0,
    contracts: 0,
    premium: 0,
    price: 0,
    momentum: 0,
    openInterest: 0,
    impliedVol: 0,
    volumeGrowth: 0,
  };

  if (!trades || trades.length === 0) {
    return tickerOverview;
  }

  const oldestTradeIndex = trades.length - 1;
  const mostRecentTradeIndex = 0;
  let totalSize = 0;
  let totalOtm = 0;
  let totalYears = 0;
  let totalPremium = 0;
  let totalPrice = 0;
  let totalOpenInterest = 0;
  let totalImpliedVol = 0;

  for (let i = 0; i < trades.length; i++) {
    totalOtm += trades[i].otmPercent * (trades[i].size || 0);
    totalYears += trades[i].yearsToExpiry * (trades[i].size || 0);
    totalSize += trades[i].size || 0;
    totalPremium += trades[i].premium;
    totalPrice += trades[i].price * (trades[i].size || 0);
    totalOpenInterest += trades[i].openInterest || 0;
    totalImpliedVol += trades[i].impliedVol * (trades[i].size || 0);
  }

  let momentum = 0;

  // NOTE: Since some tickers are traded sparsely, they can show a huge momentum with even a small increase.
  // The number of trades today must meet some minimum "sane" threshold for the normal calculation based on size.
  // If they don't meet this threshold, then the momentum is instead a function of # trades today.
  const meetsMomentumCriteria =
    trades[oldestTradeIndex].avgContractsPerDay &&
    trades[oldestTradeIndex].avgContractsPerDay > MIN_SIZE_PER_DAY &&
    trades[oldestTradeIndex].avgTradesPerDay > MIN_TRADES_PER_DAY;
  const hasMinTradesForMomentum = trades.length > MIN_TRADES_TODAY;
  if (meetsMomentumCriteria && hasMinTradesForMomentum) {
    momentum = dailyVolume / trades[oldestTradeIndex].avgContractsPerDay;

    if (trades.length < LIMIT_SMALL_TRADE_SPIKE && momentum >= MAX_EXTRAORDINARY_MOMENTUM) {
      momentum = momentum / 2;
    }
  } else if (meetsMomentumCriteria && !hasMinTradesForMomentum) {
    const decimal = sizeToMomentum(totalSize);
    momentum = 0 + decimal;
  } else {
    const momentumProxy = trades.length;
    const decimal = sizeToMomentum(totalSize);
    momentum = momentumProxy + decimal;

    if (momentum >= MAX_EXTRAORDINARY_MOMENTUM) {
      momentum = momentum / 2;
    }
  }

  tickerOverview = {
    otm: totalOtm / totalSize,
    expiry: (totalYears / totalSize) * DAYS_PER_YEAR,
    contracts: totalSize,
    premium: totalPremium,
    price: totalPrice / totalSize,
    momentum,
    openInterest: totalOpenInterest,
    impliedVol: totalImpliedVol / totalSize,
    volumeGrowth:
      (trades[mostRecentTradeIndex].avgContractsPerDay || 1) /
      (trades[oldestTradeIndex].avgContractsPerDay || 1),
  };

  return tickerOverview;
};

export default calculateTickerOverview;
