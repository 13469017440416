import styled from 'styled-components';
import { TableRow as Row } from 'components/Table';

export const TableRow = styled(Row)`
  grid-template-columns:
    repeat(1, minmax(0, 1fr))
    repeat(1, minmax(0, 1.2fr))
    repeat(1, minmax(0, 1.6fr))
    repeat(1, minmax(0, 0.9fr));
`;
