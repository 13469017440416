import { RawTrade, Trade, TradeDirection, TradeSide } from './types';
import convertTimestampToTime from 'utils/shared/time/convertTimestampToTime';
import { calculateHeatScore, MAX_HEAT_SCORE } from 'utils/shared/trades/scores';
import getExpiryString from 'utils/shared/trades/getExpiryString';
import getDaysToExpiry from 'utils/shared/trades/getDaysToExpiry';
import isUnusual from 'utils/shared/trades/isUnusual';
import isTopPosition from 'utils/shared/trades/isTopPosition';
import isGoldenSweep from 'utils/shared/trades/isGoldenSweep';

const SCORE_LIFT = 4;
const TRADE_DIRECTION_DEEP = 1.005;

const getDerivedTradeValues = (rawTrade: RawTrade, isWallstreetTimezone: boolean): Trade => {
  const trade: Trade = {
    ...rawTrade,
    daysToExpiry: getDaysToExpiry(rawTrade),
    expiryString: getExpiryString(rawTrade),
    createdTimestamp: new Date(rawTrade.createdAt).getTime(),
    heatScore: 0,
    scoreWidth: 0,
    time: '',
    timeSubscript: '',
    isUnusual: false,
    isTopPosition: false,
    isGoldenSweep: false,
  };

  const { time, timeSubscript } = convertTimestampToTime({
    timestamp: trade.createdAt,
    isWallstreetTimezone,
  });

  const heatScore = calculateHeatScore(trade) || 0;
  const scoreWidth = Math.min((heatScore / (MAX_HEAT_SCORE - SCORE_LIFT)) * 100, 100);
  let tradeDirection;

  if (rawTrade.tradeSide === TradeSide.Mid) {
    tradeDirection = TradeDirection.Mid;
  } else if (rawTrade.tradeSide === TradeSide.Ask) {
    if (rawTrade.price) {
      if (rawTrade.price >= rawTrade.nbboAsk * TRADE_DIRECTION_DEEP) {
        tradeDirection = TradeDirection.AboveAsk;
      } else {
        tradeDirection = TradeDirection.Ask;
      }
    }
  } else if (rawTrade.tradeSide === TradeSide.Bid) {
    if (rawTrade.price) {
      if (rawTrade.price >= rawTrade.nbboBid * TRADE_DIRECTION_DEEP) {
        tradeDirection = TradeDirection.BelowBid;
      } else {
        tradeDirection = TradeDirection.Bid;
      }
    }
  }

  return {
    ...trade,
    heatScore,
    scoreWidth,
    time,
    timeSubscript,
    isUnusual: isUnusual(trade),
    isTopPosition: isTopPosition(trade),
    isGoldenSweep: isGoldenSweep(trade),
    tradeDirection,
  };
};

export default getDerivedTradeValues;
