import * as React from 'react';
import Modal, { ModalWidths } from 'components/Modal';
import SymbolInfo from 'components/TradingView/SymbolInfo';
import DarkPoolLevelsTable from 'components/DarkPoolLevelsTable';
import { TICKER_FLOW_PAGE } from 'constants/pages';
import { Props } from './props';

const DarkPoolLevels: React.FC<React.PropsWithChildren<Props>> = ({ ticker, onClose }) => {
  return (
    <Modal
      titleText="darkpool-levels"
      maxHeight="100%"
      width={ModalWidths.CoverPage}
      onExit={onClose}
      mounted
      verticallyCenter
      focusDialog
      useCloseButton={false}
      scrollDisabled
    >
      <button
        aria-label="Close dialog"
        onClick={onClose}
        className="safearea-pad-top fixed right-4 top-2 text-5xl leading-none"
      >
        &times;
      </button>
      <div className="h-full pt-14 lg:pt-0">
        <div className="safearea-pad-top h-full">
          <div
            className="flex h-full min-h-full w-full min-w-full flex-col p-4"
            style={{
              maxHeight: '90vh',
              height: '90vh',
              minWidth: '100%',
              maxWidth: '1120px',
              width: '96vw',
            }}
          >
            <div className="mb-4">
              <SymbolInfo activeTicker={ticker} path={TICKER_FLOW_PAGE} />
            </div>
            <DarkPoolLevelsTable ticker={ticker} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DarkPoolLevels;
