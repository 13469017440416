import * as React from 'react';
import styled from 'styled-components';
import { MenuIcon } from '@heroicons/react/outline';

import { IconType } from 'react-icons';
import { fadeIn } from 'styles/animations';
import LogoHeader from 'components/LogoHeader';
import SlideOverNav from 'components/SlideOverNav';

interface Props {
  Icon: IconType;
  title: string;
  left?: JSX.Element;
  right?: JSX.Element;
}

export const Title = styled.div`
  height: ${({ theme }) => theme.spacing.titleHeight}px;
  max-height: ${({ theme }) => theme.spacing.titleHeight}px;
  opacity: 0;
  animation: ${fadeIn} 500ms linear 1 normal forwards;
`;

const PageTitle: React.FC<React.PropsWithChildren<Props>> = ({ Icon, title, left, right }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  return (
    <>
      <Title className="relative z-10 flex items-center justify-between p-2 pb-0">
        <div className="flex items-center">
          <LogoHeader />
          <Icon className="mr-3" /> <h1 className="font-semibold">{title}</h1> {left}
        </div>
        {right}
        <div className="sm:hidden">
          <button
            type="button"
            className="inline-flex h-9 w-9 items-center justify-center rounded-md text-zinc-300 hover:text-zinc-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </Title>
      <SlideOverNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </>
  );
};

export default PageTitle;
