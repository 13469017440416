import * as React from 'react';
import styled from 'styled-components';
import { tablet, EXTRA_COLUMN_BREAK } from 'styles/breakpoints';
import { fadeIn } from 'styles/animations';

export const StyledMainColumn = styled.div`
  grid-template-rows: auto minmax(0, 100%) minmax(0, 100%);
  height: calc(100vh - ${({ theme }) => theme.spacing.titleHeight}px);
  height: calc(
    100vh - ${({ theme }) => theme.spacing.titleHeight}px - env(safe-area-inset-top, 0) -
      env(safe-area-inset-bottom, 0)
  );
`;
export const MainColumn: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return (
    <StyledMainColumn className={`grid grid-cols-1 gap-2 p-2 lg:pr-0 ${className}`}>
      {children}
    </StyledMainColumn>
  );
};

export const StyledMainColumnDynamic = styled.div<{ autoRows: number; rows: number }>`
  grid-template-rows: repeat(${({ autoRows }) => autoRows}, auto) repeat(
      ${({ rows }) => rows},
      minmax(0, 100%)
    );
  height: calc(100vh - ${({ theme }) => theme.spacing.titleHeight}px);
  height: calc(
    100vh - ${({ theme }) => theme.spacing.titleHeight}px - env(safe-area-inset-top, 0) -
      env(safe-area-inset-bottom, 0)
  );

  ${tablet()} {
    grid-template-rows: repeat(${({ autoRows }) => autoRows}, auto) repeat(1, minmax(0, 100%));
  }
`;
export const MainColumnDynamic: React.FC<React.PropsWithChildren<{
  className?: string;
  rows: number;
  autoRows: number;
}>> = ({ children, rows, autoRows, className = '' }) => {
  return (
    <StyledMainColumnDynamic
      rows={rows}
      autoRows={autoRows}
      className={`grid grid-cols-1 gap-2 p-2 lg:pr-0 ${className}`}
    >
      {children}
    </StyledMainColumnDynamic>
  );
};

const StyledStackedColumn = styled.div<{
  numRows: number;
  hasHeader: boolean;
  enableTripleColumns?: boolean;
  showTall?: boolean;
}>`
  height: calc(100vh - ${({ theme }) => theme.spacing.titleHeight}px);
  height: calc(
    100vh - ${({ theme }) => theme.spacing.titleHeight}px - env(safe-area-inset-top, 0) -
      env(safe-area-inset-bottom, 0)
  );
  width: 100%;
  grid-template-rows: ${({ numRows, hasHeader }) =>
    `${hasHeader ? 'repeat(1, auto) ' : ''}repeat(${numRows}, minmax(0, 100%))`};

  @media (min-width: ${EXTRA_COLUMN_BREAK}px) {
    ${({ enableTripleColumns }) => (enableTripleColumns ? 'padding-right: 0;' : '')};
    grid-template-rows: ${({ numRows, showTall }) =>
      `${showTall ? 'repeat(1, auto) ' : ''}repeat(${numRows}, minmax(0,100%))`};
  }
`;
export const StackedColumn: React.FC<React.PropsWithChildren<{
  numRows?: number;
  hasHeader?: boolean;
  enableTripleColumns?: boolean;
  showTall?: boolean;
}>> = ({ children, numRows = 2, hasHeader = false, enableTripleColumns, showTall }) => {
  return (
    <StyledStackedColumn
      hasHeader={hasHeader}
      numRows={numRows}
      enableTripleColumns={enableTripleColumns}
      className="hidden max-h-screen grid-cols-1 flex-col gap-2 overflow-hidden p-2 pl-0 lg:grid"
      showTall={showTall}
    >
      {children}
    </StyledStackedColumn>
  );
};

export const StyledLayoutGrid = styled.div<{ enableTripleColumns?: boolean }>`
  grid-template-columns: minmax(0, 68%) minmax(0, 32%);
  height: calc(100vh - ${({ theme }) => theme.spacing.titleHeight}px);
  height: calc(
    100vh - ${({ theme }) => theme.spacing.titleHeight}px - env(safe-area-inset-top, 0) -
      env(safe-area-inset-bottom, 0)
  );
  opacity: 0;
  animation: ${fadeIn} 500ms linear 2000ms 1 normal forwards;

  ${tablet()} {
    grid-template-columns: 1fr;
  }

  @media (min-width: ${EXTRA_COLUMN_BREAK}px) {
    grid-template-columns: ${({ enableTripleColumns }) =>
      enableTripleColumns
        ? 'minmax(0, 52%) minmax(0, 24%) minmax(0, 24%)'
        : 'minmax(0, 66.66667%) minmax(0, 33.33333%)'};
  }
`;
export const LayoutGrid: React.FC<React.PropsWithChildren<{ enableTripleColumns?: boolean }>> = ({
  children,
  enableTripleColumns,
}) => {
  return (
    <StyledLayoutGrid className="grid gap-2" enableTripleColumns={enableTripleColumns}>
      {children}
    </StyledLayoutGrid>
  );
};
