import { SortDirection, ColumnType } from 'utils/shared/trades/types';

export interface LevelsHeaderProps {
  aggregateSortKey: string;
  setAggregateSortKey: (key: string) => void;
  aggregateSortDirection: SortDirection;
  setAggregateSortDirection: (direction: SortDirection) => void;
  ticker: string;
}

export interface LevelProps {
  ticker: string;
}

export const DEFAULT_AGGREGATE_KEY_INDEX = 0;
export const LEVELS_TABLE_HEADERS = [
  { name: 'Level', sortKey: 'price', columnType: ColumnType.Number },
  { name: 'Prints', sortKey: 'prints', columnType: ColumnType.Number },
  { name: 'Size', sortKey: 'volume', columnType: ColumnType.Number },
  { name: 'Premium', sortKey: 'amount', columnType: ColumnType.Number },
  { name: 'Strength', sortKey: 'strength', columnType: ColumnType.Number },
];
export const DEFAULT_LEVELS_KEY = LEVELS_TABLE_HEADERS[DEFAULT_AGGREGATE_KEY_INDEX].sortKey;
