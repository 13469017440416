import { ApolloError } from '@apollo/client';
import {
  GetDarkPoolTradesQuery,
  GetTickerDarkPoolTradesQuery,
  GetFullFeedTickerEquityPrintFlowQuery,
} from 'types/generated/client';
import { Viewer } from 'constants/user';
import {
  ColumnType,
  SortDirection,
  SignaturePrintStatus,
  Sentiment,
} from 'utils/shared/trades/types';

export const DEFAULT_FLOW_KEY_INDEX = 0;
export const DATE_COL = { name: 'Date', sortKey: 'date', columnType: ColumnType.Date };
export const DARK_POOL_TABLE_HEADERS = [
  { name: 'Time', sortKey: 'timestampIso', columnType: ColumnType.Date },
  { name: 'Ticker', sortKey: 'ticker', columnType: ColumnType.Text },
  // { name: 'Sector', sortKey: 'sector', columnType: ColumnType.Text },
  { name: 'Price', sortKey: 'price', columnType: ColumnType.Number },
  { name: 'Size', sortKey: 'size', columnType: ColumnType.Number },
  { name: 'Amount', sortKey: 'amount', columnType: ColumnType.Number },
  { name: 'Levels', sortKey: '', columnType: ColumnType.Number },
];
export const DEFAULT_FLOW_KEY = DARK_POOL_TABLE_HEADERS[DEFAULT_FLOW_KEY_INDEX].sortKey;

export type DarkPoolTrade =
  | Omit<GetDarkPoolTradesQuery['equity_print_flow'][0], '__typename'>
  | Omit<GetTickerDarkPoolTradesQuery['ticker_equity_print_flow'][0], '__typename'>
  | Omit<GetFullFeedTickerEquityPrintFlowQuery['full_feed_equity_print_flow'][0], '__typename'>;

export interface OwnProps {
  watchListTickers: string[];
  isWatchListOnly: boolean;
  setIsWatchListOnly?: (isWatchListOnly: boolean) => void;
  hasWatchListItems: boolean;
  viewer: Viewer;
  searchValue: string;
  includeDate?: boolean;
  right?: JSX.Element;
}

export interface DarkPoolTableHeaderProps {
  flowSortKey: string;
  setFlowSortKey: (key: string) => void;
  flowSortDirection: SortDirection;
  setFlowSortDirection: (direction: SortDirection) => void;
  isWatchListOnly: boolean;
  setIsWatchListOnly?: (isWatchListOnly: boolean) => void;
  hasWatchListItems: boolean;
  includeDate?: boolean;
  right?: JSX.Element;
  setShowInfo: any;
}

interface DerivedPrintValues {
  // sector: string;
  signaturePrintStatus: SignaturePrintStatus;
  signaturePrintSentiment: Sentiment;
  signaturePrintNumber: number;
}

export interface DarkPoolTableRowProps {
  trade: Omit<GetDarkPoolTradesQuery['equity_print_flow'][0], '__typname'> & DerivedPrintValues;
  watchListTickers: string[];
  signaturePrintCount: number;
  includeDate?: boolean;
  trackRowAsAnimated?: () => void;
  hasBeenAnimated?: boolean;
  setDarkPoolLevelTicker: (ticker: string) => void;
  showInfo: boolean;
}

export interface Props extends OwnProps {
  hasAccess: boolean;
  trades: Array<DarkPoolTrade>;
  loading: boolean;
  error?: ApolloError;
}
