import { CallPut } from './types';

const calculatePercentOtm = (strike: number, spot: number, type: string) => {
  if (type === CallPut.Call) {
    return Math.round(((strike - spot) / spot) * 100);
  } else if (type === CallPut.Put) {
    return Math.round(((spot - strike) / spot) * 100);
  }

  return 0;
};

export default calculatePercentOtm;
