import isSweep from './isSweep';
import isTopPosition from './isTopPosition';
import { Trade } from './types';

const GOLDEN_SWEEP_PREMIUM = 1_000_000;

const isGoldenSweep = (trade: Trade) => {
  return isSweep(trade) && isTopPosition(trade) && trade.premium >= GOLDEN_SWEEP_PREMIUM;
};

export default isGoldenSweep;
