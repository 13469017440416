import * as React from 'react';
import Link from 'components/Link';
import {
  RiLightbulbFlashLine,
  //RiBarChartHorizontalLine
} from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
import { BsBarChartSteps } from 'react-icons/bs';
import { MdInfoOutline } from 'react-icons/md';
import { Switch, Transition } from '@headlessui/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { Timezone_Location_Enum } from 'types/generated/client';
import convertTimestampToTime from 'utils/shared/time/convertTimestampToTime';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
// import { getSectorShortName } from 'utils/shared/trades/sectors';
import { TICKER_FLOW_PAGE } from 'constants/pages';
import SectionTitle from 'components/SectionTitle';
import TickerName from 'components/TickerName';
import PulseLoader from 'components/Loading/Pulse';
import {
  SortDirection,
  EquityPrintOverview,
  SignaturePrintStatus,
  Sentiment,
} from 'utils/shared/trades/types';
import getSignaturePrintStatus from 'utils/shared/trades/getSignaturePrintStatus';
import getSignaturePrintSentiment from 'utils/shared/trades/getSignaturePrintSentiment';
import { getIsGoldenDarkPool } from 'utils/shared/trades/getIsGoldenDarkPool';
import { FINRA_EXCHANGE_ID, LARGE_TRADE_MINIMUM } from 'constants/darkpool';
import {
  TableWrapper,
  TableCell,
  TableCellNoPad,
  TableHeader,
  TableBody,
  HeaderCell,
  MutedText,
  HeaderCellWrapper,
  HeaderIcon,
  IconSortDesc,
  IconSortAsc,
  WatchListIcon,
} from 'components/Table';
import DarkPoolLevels from 'components/DarkPoolLevels';
import PermissionedBlock from 'components/PermissionedBlock';

import classNames from 'styles/utils/classNames';
import {
  Props,
  DarkPoolTableHeaderProps,
  DarkPoolTableRowProps,
  DARK_POOL_TABLE_HEADERS,
  DEFAULT_FLOW_KEY,
  DATE_COL,
} from './props';

import {
  HighVolumeIndicator,
  DarkPoolRow,
  HighlightedPrice,
  SentimentDot,
  //  HeaderButton
} from './styles';

const TITLE_NAMES = DARK_POOL_TABLE_HEADERS;
const ROW_HEIGHT = 38.19;

const Header: React.FC<React.PropsWithChildren<DarkPoolTableHeaderProps>> = React.memo(
  ({
    flowSortKey,
    setFlowSortKey,
    flowSortDirection,
    setFlowSortDirection,
    isWatchListOnly,
    setIsWatchListOnly,
    hasWatchListItems,
    includeDate,
    right,
    setShowInfo,
  }) => {
    const columns = includeDate ? [DATE_COL, ...TITLE_NAMES] : TITLE_NAMES;
    return (
      <TableHeader>
        <div className="mb-0 flex justify-between">
          <div className="flex w-full">
            <SectionTitle
              className="w-full"
              Icon={RiLightbulbFlashLine}
              title="Dark Pool Prints"
              style={{ marginBottom: 0 }}
              right={right}
            />
            {!!setIsWatchListOnly && (
              <Switch.Group as="div" className="ml-4 flex items-center">
                <Switch
                  checked={isWatchListOnly}
                  onChange={setIsWatchListOnly}
                  className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'bg-yellow-400' : 'bg-gray-600'}
          pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
        `}
                  />
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'translate-x-3' : 'translate-x-0'} ${
                      isWatchListOnly ? 'bg-white' : 'bg-gray-300'
                    } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out`}
                  />
                </Switch>
                <div
                  className={`ml-2 text-xs ${
                    isWatchListOnly ? 'text-yellow-400' : 'text-gray-600'
                  }`}
                >
                  <AiFillStar />
                </div>
                {isWatchListOnly && !hasWatchListItems && (
                  <Switch.Label as="span" className="ml-2 leading-none" style={{ lineHeight: 0 }}>
                    <span className="text-xs font-medium leading-none text-yellow-400">
                      Add items to your watchlist to appear in the feed
                    </span>
                  </Switch.Label>
                )}
              </Switch.Group>
            )}
          </div>
          {/* <HeaderButton>
            <span className="mr-1">Levels</span>
            <RiBarChartHorizontalLine />
          </HeaderButton> */}
        </div>
        <DarkPoolRow isHeader removePad includeDate={includeDate}>
          {columns.map((t, i) => {
            const isActiveSort =
              t.sortKey === flowSortKey && flowSortDirection !== SortDirection.None;
            const isFinalRow = i === columns.length - 1;

            if (isFinalRow && includeDate) {
              return null;
            }

            return (
              <HeaderCellWrapper
                key={t.name}
                removePad={i + 1 === TITLE_NAMES.length}
                isActiveSort={isActiveSort}
                onClick={() => {
                  if (isFinalRow) {
                    setShowInfo((showInfo: boolean) => !showInfo);
                    return;
                  }

                  if (flowSortKey !== t.sortKey) {
                    setFlowSortKey(t.sortKey);
                    setFlowSortDirection(SortDirection.Desc);
                  } else if (
                    flowSortKey === t.sortKey &&
                    flowSortDirection === SortDirection.Desc
                  ) {
                    setFlowSortDirection(SortDirection.Asc);
                  } else if (flowSortKey === t.sortKey && flowSortDirection === SortDirection.Asc) {
                    setFlowSortKey(DEFAULT_FLOW_KEY);
                    setFlowSortDirection(SortDirection.None);
                  }
                }}
              >
                <HeaderCell>
                  <span
                    className="cell-name flex items-center"
                    onMouseOver={() => {
                      if (isFinalRow) {
                        setShowInfo(true);
                      }
                    }}
                    onMouseOut={() => {
                      if (isFinalRow) {
                        setShowInfo(false);
                      }
                    }}
                  >
                    {t.name}{' '}
                    {isFinalRow && (
                      <div className="relative flex items-center">
                        <MdInfoOutline className="ml-1 text-xs text-graph-purple" />
                      </div>
                    )}
                  </span>
                  {!isFinalRow && isActiveSort && flowSortDirection === SortDirection.Desc && (
                    <HeaderIcon>
                      <IconSortDesc />
                    </HeaderIcon>
                  )}
                  {!isFinalRow && isActiveSort && flowSortDirection === SortDirection.Asc && (
                    <HeaderIcon>
                      <IconSortAsc />
                    </HeaderIcon>
                  )}
                </HeaderCell>
              </HeaderCellWrapper>
            );
          })}
        </DarkPoolRow>
      </TableHeader>
    );
  },
);

const Row: React.FC<React.PropsWithChildren<DarkPoolTableRowProps>> = ({
  trade,
  watchListTickers,
  signaturePrintCount,
  includeDate,
  trackRowAsAnimated,
  hasBeenAnimated,
  setDarkPoolLevelTicker,
  showInfo,
}) => {
  const { user: currentUser } = useCurrentUser();
  const timezoneLocation = currentUser?.timezoneLocation || Timezone_Location_Enum.Current;
  const isWallstreetTimezone = timezoneLocation === Timezone_Location_Enum.Wallstreet;

  const isWatchListTrade = !!trade.ticker && watchListTickers.includes(trade.ticker);
  const { time, timeSubscript } = convertTimestampToTime({
    timestamp: trade.timestampIso,
    isWallstreetTimezone,
  });

  React.useEffect(() => {
    return () => {
      if (trackRowAsAnimated && !hasBeenAnimated) {
        trackRowAsAnimated();
      }
    };
  }, [hasBeenAnimated, trackRowAsAnimated]);

  return (
    <DarkPoolRow
      tableBodyRow
      isAnimated={!hasBeenAnimated}
      signaturePrintStatus={trade.signaturePrintStatus}
      includeDate={includeDate}
      style={{ height: `${ROW_HEIGHT}px` }}
      isLitPool={trade.exchangeId !== FINRA_EXCHANGE_ID}
    >
      {includeDate && (
        <TableCell>
          <MutedText>{trade.date}</MutedText>
        </TableCell>
      )}
      <TableCell>
        <MutedText>
          {time} {timeSubscript}
        </MutedText>
      </TableCell>
      <TableCell className="relative">
        {isWatchListTrade && (
          <WatchListIcon className="absolute text-yellow-400">
            <AiFillStar />
          </WatchListIcon>
        )}
        <Link href={`${TICKER_FLOW_PAGE}/${trade.ticker}`}>
          <TickerName name={trade?.tickerDetails?.name || ''}>{trade.ticker}</TickerName>
          {trade.signaturePrintNumber > 0 && (
            <span className="ml-1 text-gray-400" style={{ fontSize: '.6rem' }}>
              {signaturePrintCount - trade.signaturePrintNumber + 1}
            </span>
          )}
        </Link>
      </TableCell>
      {/* <TableCell>{trade.sector}</TableCell> */}
      <TableCell>
        <span className="flex items-center">
          ${trade.price.toFixed(2)}{' '}
          {trade.signaturePrintSentiment !== Sentiment.None && (
            <SentimentDot sentiment={trade.signaturePrintSentiment} />
          )}
        </span>
      </TableCell>
      <TableCell>
        <HighVolumeIndicator isHighVolume={getIsGoldenDarkPool(trade.size, trade.avgVolume)}>
          {trade.size?.toLocaleString()}
        </HighVolumeIndicator>
      </TableCell>
      <TableCell>
        <HighlightedPrice isLargePrice={trade.amount >= LARGE_TRADE_MINIMUM}>
          ${abbreviateNumber(trade.amount)}
        </HighlightedPrice>
      </TableCell>
      {!includeDate && (
        <TableCellNoPad>
          <button
            onClick={() => setDarkPoolLevelTicker(trade.ticker || '')}
            style={{ marginLeft: '-6px' }}
            className={classNames(
              showInfo ? 'bg-button-color' : 'bg-transparent',
              'flex items-center rounded border border-button-color px-2 py-1 transition-colors duration-150 hover:bg-button-color',
            )}
          >
            <BsBarChartSteps className="mr-1 h-2 w-2" /> View
          </button>
        </TableCellNoPad>
      )}
    </DarkPoolRow>
  );
};

const DarkPoolTable: React.FC<React.PropsWithChildren<Props>> = ({
  watchListTickers,
  isWatchListOnly,
  setIsWatchListOnly,
  hasWatchListItems,
  searchValue,
  trades = [],
  hasAccess,
  loading,
  error,
  includeDate,
  right,
}) => {
  const searchTerm = (searchValue || '').trim().toUpperCase();
  const [flowSortKey, setFlowSortKey] = React.useState(DEFAULT_FLOW_KEY);
  const [flowSortDirection, setFlowSortDirection] = React.useState(SortDirection.None);
  const [hasLoadedTrades, setHasLoadedTrades] = React.useState(false);
  const [animatedRows, setAnimatedRows] = React.useState<Record<string, boolean>>({});
  const [darkPoolLevelTicker, setDarkPoolLevelTicker] = React.useState('');
  const [showInfo, setShowInfo] = React.useState(false);

  React.useEffect(() => {
    if (!hasLoadedTrades && trades?.length) {
      const rows: Record<string, boolean> = {};
      trades.forEach((t) => {
        rows[t.id] = true;
      });
      setHasLoadedTrades(true);
      setAnimatedRows(rows);
    }
  }, [trades, hasLoadedTrades]);

  // NOTE: This assumes they come in sorted by most recent first
  const equityPrintOverview = React.useMemo(
    () =>
      trades.reduce((acc, trade) => {
        if (!!trade.ticker && !acc[trade.ticker]) {
          acc[trade.ticker] = {
            open: trade.open,
            high: trade.high,
            low: trade.low,
            lastPrice: trade.lastTradePrice,
            avgPrice: trade.avgPrice,
          };
        }

        return acc;
      }, {} as Record<string, EquityPrintOverview>),
    [trades],
  );

  const { displayedTrades, signaturePrintCount } = React.useMemo(() => {
    const signaturePrintSummary: Record<string, number> = {};
    let sortKey = DEFAULT_FLOW_KEY;

    if (
      !flowSortKey ||
      flowSortKey === DEFAULT_FLOW_KEY ||
      flowSortDirection === SortDirection.None
    ) {
      sortKey = DEFAULT_FLOW_KEY;
    } else {
      sortKey = flowSortKey;
    }

    const filteredTrades = trades
      .map((t) => {
        const signaturePrintStatus = getSignaturePrintStatus(
          t,
          equityPrintOverview[t.ticker || ''],
        );
        let signaturePrintNumber = 0;

        if (signaturePrintStatus !== SignaturePrintStatus.None) {
          if (!signaturePrintSummary[t.ticker || '']) {
            signaturePrintSummary[t.ticker || ''] = 0;
          }
          signaturePrintSummary[t.ticker || '']++;
          signaturePrintNumber = signaturePrintSummary[t.ticker || ''];
        }

        return {
          ...t,
          signaturePrintStatus: signaturePrintStatus,
          signaturePrintSentiment: getSignaturePrintSentiment({
            price: t.price,
            lastPrice: equityPrintOverview[t.ticker || ''].lastPrice,
            isSignatureEtfTicker: t.isSignatureEtfTicker || false,
          }),
          // sector: getSectorShortName(t?.tickerDetails?.sector || ''),
          signaturePrintNumber: signaturePrintNumber || 0,
        };
      })
      .filter((item) => {
        const passesWatchListTest = isWatchListOnly
          ? watchListTickers.includes(item.ticker || '')
          : true;
        const passesSearchTest = searchTerm ? item.ticker?.includes(searchTerm) : true;
        return passesWatchListTest && passesSearchTest;
      });

    const sortedTrades = filteredTrades.sort((a, b) => {
      let first = flowSortDirection === SortDirection.Asc ? a : b;
      let last = flowSortDirection === SortDirection.Asc ? b : a;

      // @ts-ignore
      if (first[sortKey] === last[sortKey]) {
        return (first.size || 0) - (last.size || 0);
      }

      // @ts-ignore
      if (first[sortKey] < last[sortKey]) {
        return -1;
      }
      // @ts-ignore
      if (first[sortKey] > last[sortKey]) {
        return 1;
      }
      return 0;
    });

    return { displayedTrades: sortedTrades, signaturePrintCount: signaturePrintSummary };
  }, [
    trades,
    equityPrintOverview,
    isWatchListOnly,
    watchListTickers,
    searchTerm,
    flowSortKey,
    flowSortDirection,
  ]);

  const RenderRow = React.useCallback(
    ({ index, style }: any) => {
      const trade = displayedTrades[index];

      return (
        <div style={style}>
          <Row
            key={trade.id}
            includeDate={includeDate}
            trade={trade}
            watchListTickers={watchListTickers}
            signaturePrintCount={signaturePrintCount[trade.ticker || ''] || 0}
            hasBeenAnimated={animatedRows[trade.id]}
            setDarkPoolLevelTicker={setDarkPoolLevelTicker}
            trackRowAsAnimated={() => {
              setAnimatedRows((prevState) => ({ ...prevState, [trade.id]: true }));
            }}
            showInfo={showInfo}
          />
        </div>
      );
    },
    [displayedTrades, animatedRows, watchListTickers, setDarkPoolLevelTicker, showInfo],
  );

  return (
    <>
      <TableWrapper className="relative flex h-full w-full flex-auto flex-col overflow-x-auto overflow-y-hidden">
        <Header
          includeDate={includeDate}
          flowSortKey={flowSortKey}
          setFlowSortKey={setFlowSortKey}
          flowSortDirection={flowSortDirection}
          setFlowSortDirection={setFlowSortDirection}
          isWatchListOnly={isWatchListOnly}
          setIsWatchListOnly={setIsWatchListOnly}
          hasWatchListItems={hasWatchListItems}
          right={right}
          setShowInfo={setShowInfo}
          // isSoundEnabled={isSoundEnabled}
          // setIsSoundEnabled={setIsSoundEnabled}
        />
        <TableBody animateRows className="relative h-full w-full">
          {loading && (!trades || trades?.length === 0) ? (
            <div className="flex h-full items-center justify-center">
              <PulseLoader />
            </div>
          ) : !hasAccess ? (
            <PermissionedBlock />
          ) : !!error && (!trades || trades?.length === 0) ? (
            <div className="flex h-full items-center justify-center text-gray-400">&nbsp;</div>
          ) : (
            <div className="h-full w-full overflow-hidden">
              <AutoSizer>
                {({ width, height }) => (
                  <FixedSizeList
                    height={height}
                    itemCount={displayedTrades.length}
                    itemSize={ROW_HEIGHT}
                    width={width}
                  >
                    {RenderRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
          )}
        </TableBody>
        <Transition
          show={showInfo}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute bottom-0 left-0 flex w-full items-center justify-center bg-darkpool p-6 text-center text-sm text-white">
            Tracking the price level that dark pool prints are trading over time helps identify
            support and resistance levels. The more volume that was transacted at that price, the
            stronger the support level is likely to be.
          </div>
        </Transition>
      </TableWrapper>
      {!!darkPoolLevelTicker && (
        <DarkPoolLevels ticker={darkPoolLevelTicker} onClose={() => setDarkPoolLevelTicker('')} />
      )}
    </>
  );
};

export default DarkPoolTable;
