import { Trade, CallPut } from './types';

export const getVolumePutFlow = (trades: Trade[]) => {
  let puts = 0;
  let calls = 0;

  trades.forEach((trade) => {
    if (trade.cp === CallPut.Put) {
      puts += trade.size || 0;
    }
    if (trade.cp === CallPut.Call) {
      calls += trade.size || 0;
    }
  });

  if (calls + puts === 0) {
    return 0;
  }

  return puts / (calls + puts);
};

export const getPremiumPutFlow = (trades: Trade[]) => {
  let puts = 0;
  let calls = 0;

  trades.forEach((trade) => {
    if (trade.cp === CallPut.Put) {
      puts += trade.premium;
    }
    if (trade.cp === CallPut.Call) {
      calls += trade.premium;
    }
  });

  if (calls + puts === 0) {
    return 0;
  }

  return puts / (calls + puts);
};

export const calculatePutFlow = (trades: Trade[]) => {
  let putPremium = 0;
  let callPremium = 0;
  let putVolume = 0;
  let callVolume = 0;

  trades.forEach((trade) => {
    if (trade.cp === CallPut.Put) {
      putPremium += trade.premium;
      putVolume += trade.size || 0;
    }
    if (trade.cp === CallPut.Call) {
      callPremium += trade.premium;
      callVolume += trade.size || 0;
    }
  });

  const putFlowPremium =
    putPremium + callPremium === 0 ? 0 : putPremium / (putPremium + callPremium);
  const putFlowVolume = putVolume + callVolume === 0 ? 0 : putVolume / (putVolume + callVolume);

  return { putFlowPremium, putFlowVolume, putVolume, callVolume, putPremium, callPremium };
};
