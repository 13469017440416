import * as React from 'react';
import styled from 'styled-components';

export const CardRow: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <div className="flex flex-col md:flex-row">{children}</div>;
};

export const SentimentText = styled.div`
  font-size: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;
