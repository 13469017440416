import * as React from 'react';
import styled from 'styled-components';

const TickerNameWrapper = styled.div`
  min-height: 22px;
  background-color: ${({ theme }) => theme.color.buttonPrimary};
  /* color: ${({ theme }) => theme.color.backgroundPrimary}; */
  /* border: 1px solid ${({ theme }) => theme.color.border}; */
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 8px;
  padding: 5px 8px;
  line-height: 1;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
`;

const TickerHover = styled.div`
  position: relative;
  width: 0;
  opacity: 0;
  transition: opacity 250ms ease, transform 250ms ease;
  z-index: -1;
  transform: translateX(-8px);

  &:hover {
    opacity: 0.95;
    z-index: 0;
    transform: translateX(0);
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  width: 100%;

  &:hover {
    + div {
      opacity: 0.95;
      z-index: 0;
      transform: translateX(0);
    }
  }
`;

const TickerName: React.FC<React.PropsWithChildren<{ name: string }>> = ({ children, name }) => {
  return (
    <div className="inline-flex">
      <ChildWrapper>{children}</ChildWrapper>
      <TickerHover>
        <TickerNameWrapper>{name}</TickerNameWrapper>
      </TickerHover>
    </div>
  );
};

export default TickerName;
