import * as React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

const Img = styled.img`
  height: 34px;
  margin-right: 1rem;
`;

const LogoHeader = () => {
  const [shouldShowLogo, setShouldShowLogo] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    if (router.isReady) {
      const { logo } = router.query;
      if (logo === 'true') {
        setShouldShowLogo(true);
      }
    }
  }, [router, router.isReady]);

  if (!shouldShowLogo) {
    return null;
  }

  return <Img src="/images/logo-io.png" />;
};

export default LogoHeader;
