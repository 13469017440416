import styled from 'styled-components';

export const SCORE_HEIGHT = 8;

export const ScoreWrapper = styled.div`
  height: ${SCORE_HEIGHT}px;
  width: 100%;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
`;

export const CallScoreWrapper = styled(ScoreWrapper)`
  background-color: ${({ theme }) => theme.color.graphCall};
`;

export const ScoreBar = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 9999px;
`;

export const HeatScore = styled(ScoreBar)<{ scoreWidth: number }>`
  background: linear-gradient(90deg, #f9cb28, #ff4d4d);
  height: 100%;
  width: ${({ scoreWidth }) => scoreWidth}%;
  opacity: 0.9;
`;

export const ScoreSpace = styled.div<{ width: number }>`
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  width: ${({ width }) => width}%;
  height: 100%;
`;
