import React from 'react';
import Link from 'components/Link';
import { ButtonLink } from 'styles/elements';
import { mobile } from 'styles/breakpoints';
import styled from 'styled-components';
import { LOGIN_PAGE, SIGNUP_PAGE, PRICING_PAGE } from 'constants/pages';
import useViewer from 'hooks/useViewer';
import { AuthStatus } from 'constants/auth';

const ActionButton = styled(ButtonLink)`
  width: auto;
  font-size: 14px;
  line-height: 1;
  padding: 10px 20px;
`;

export const TransparentButton = styled(ActionButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.color.textPrimary};

  &:hover {
    border-color: ${({ theme }) => theme.color.buttonPrimary};
    background-color: ${({ theme }) => theme.color.buttonPrimary};
    color: ${({ theme }) => theme.color.textPrimary};
  }

  ${mobile()} {
    display: none;
  }
`;

interface Props {
  title?: string;
  description?: React.ReactNode;
}

const DefaultDescription = () => {
  return (
    <div className="space-y-2">
      <div className="font-semibold">
        Stop settling for average returns! Join thousands of top traders using award-nominated tools
        to beat the market.
      </div>
      <div>
        Unlock real-time options flow, dark pool, automated technical analysis, and more —
        everything you need to trade smarter, faster, and more profitably.
      </div>
    </div>
  );
};

export default function PermissionedBlock({
  title = 'Boost Your Win Rate with InsiderFinance',
  description = <DefaultDescription />,
}: Props) {
  const viewer = useViewer();

  return (
    <div className="flex h-full items-center justify-center p-3 text-center">
      <div>
        <div className="text-lg font-semibold">{title}</div>
        <div className="mt-2 text-pretty text-xs text-palette-black-8">{description}</div>
        <div className="mt-4">
          <div className="flex justify-center gap-2">
            <Link className="w-full shrink-0" href={PRICING_PAGE}>
              <ActionButton className="!w-full !max-w-xs">Gain Your Edge Now</ActionButton>
            </Link>
          </div>
          {viewer.status !== AuthStatus.User && (
            <div className="mt-4 flex w-full items-center justify-center gap-1 text-xs ">
              <Link href={SIGNUP_PAGE} className="font-medium text-palette-black-8 underline">
                Create Account
              </Link>
              <span>or</span>
              <Link href={LOGIN_PAGE} className="font-medium text-palette-black-8 underline">
                Log In
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
