import styled from 'styled-components';
import { Button } from 'styles/elements';
import { TableRow } from 'components/Table';
import { SignaturePrintStatus, Sentiment } from 'utils/shared/trades/types';
import { rgba } from 'styles/utils/color';

export const RowWrapper = styled.div<{ isHighVolume?: boolean }>`
  border: 1px solid
    ${({ theme, isHighVolume }) => (isHighVolume ? theme.color.mixedSentiment : 'transparent')};
`;

export const DarkPoolRow = styled(TableRow)<{
  tableBodyRow?: boolean;
  signaturePrintStatus?: SignaturePrintStatus;
  includeDate?: boolean;
  style?: object;
  isLitPool?: boolean;
  isHeader?: boolean;
}>`
  grid-template-columns:
    ${({ includeDate }) => (includeDate ? 'minmax(68px, .8fr)' : '')}
    minmax(56px, 0.8fr) minmax(60px, 0.9fr) minmax(64px, 1fr)
    minmax(72px, 1.1fr)
    minmax(64px, 1fr)
    ${({ includeDate }) => (includeDate ? '' : 'minmax(64px, 1fr)')};
  min-height: 34px;
  background: ${({ signaturePrintStatus, isLitPool, theme, isHeader }) => {
    if (isHeader) {
      return 'transparent';
    }

    if (isLitPool) {
      return theme.color.paper;
    }

    if (!signaturePrintStatus || signaturePrintStatus === SignaturePrintStatus.None) {
      return 'transparent';
    }

    if (signaturePrintStatus === SignaturePrintStatus.Buy) {
      return `linear-gradient(
            -90deg,
            rgba(0, 0, 0, 0) 2%,
            ${rgba(theme.color.graphCall, 0.19)} 32%,
            ${rgba(theme.color.graphCall, 0.14)} 74%,
            rgba(0, 0, 0, 0) 98%);`;
    } else {
      return `linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 2%,
        ${rgba(theme.color.graphPut, 0.19)} 32%,
        ${rgba(theme.color.graphPut, 0.14)} 74%,
        rgba(0, 0, 0, 0) 98%
      )`;
    }
  }};
`;

export const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  box-shadow: ${({ theme }) => theme.shadow.high};
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 8px;
  width: auto;
  font-weight: 600;
  padding: 6px 10px;

  &:focus {
    outline: none;
  }
`;

export const HighlightedPrice = styled.span<{ isLargePrice: boolean }>`
  color: ${({ isLargePrice, theme }) => (isLargePrice ? theme.color.topPosition : 'inherit')};
`;

const DOT_SIZE = 6;
export const SentimentDot = styled.div<{ sentiment: Sentiment }>`
  margin-left: ${DOT_SIZE}px;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: 50%;
  background-color: ${({ theme, sentiment }) =>
    sentiment === Sentiment.Bullish ? theme.color.callBackground : theme.color.putBackground};
`;

export const HighVolumeIndicator = styled.div<{ isHighVolume?: boolean }>`
  border: 1px solid
    ${({ isHighVolume, theme }) => (isHighVolume ? theme.color.mixedSentiment : 'transparent')};
  padding: ${({ isHighVolume }) => (isHighVolume ? '6px 2px' : 0)};
  min-width: 46px;
  text-align: ${({ isHighVolume }) => (isHighVolume ? 'center' : 'left')};
  border-radius: 3px;
  /* font-weight: ${({ isHighVolume }) => (isHighVolume ? 700 : 500)}; */
  line-height: 1;
  font-size: 0.65rem;
  width: 100%;
`;
