import getDaysToExpiry from './getDaysToExpiry';
import { Trade } from './types';

const UNUSUAL_OTM_PERCENT = 10;
const UNUSUAL_EXPIRY_DAYS = 35;

const isUnusual = (trade: Trade) => {
  const daysToExpiry = getDaysToExpiry(trade);
  return trade.otmPercent >= UNUSUAL_OTM_PERCENT && daysToExpiry <= UNUSUAL_EXPIRY_DAYS;
};

export default isUnusual;
