import { SignaturePrintStatus, EquityPrint } from './types';

const getSignaturePrintStatus = (equityPrintTrade: EquityPrint, { open }: { open: number }) => {
  const isSignaturePrint =
    equityPrintTrade.isSignatureVolume || equityPrintTrade.isOutsideDailyRange;

  if (!isSignaturePrint) {
    return SignaturePrintStatus.None;
  }

  if (equityPrintTrade.price <= open) {
    return SignaturePrintStatus.Buy;
  } else {
    return SignaturePrintStatus.Sell;
  }
};

export default getSignaturePrintStatus;
