import getDaysToExpiry from './getDaysToExpiry';
import isUnusual from './isUnusual';
import isSweep from './isSweep';
import { Trade } from './types';

const UNUSUAL_SCORE_MULTIPLE = 1.5;
const OTM_SCORE_MULTIPLE = 1.5;
export const MAX_HEAT_SCORE =
  4 + 0 * UNUSUAL_SCORE_MULTIPLE + 0 + 3 + 2 + 5 * OTM_SCORE_MULTIPLE + 0 + 4 + 4 + 2 + 0;
export const MAX_AGGRESSIVE_SCORE = 1;

export const calculatePremiumScore = (trade: Trade) => {
  const premiumToAvg = trade.premium / trade.avgPremiumPerTrade;

  if (premiumToAvg < 1) {
    return 0;
  } else if (premiumToAvg >= 1 && premiumToAvg < 3) {
    return 1;
  } else if (premiumToAvg >= 3 && premiumToAvg < 5) {
    return 2;
  } else if (premiumToAvg >= 5 && premiumToAvg < 10) {
    return 3;
  } else if (premiumToAvg >= 10) {
    return 4;
  }

  return 0;
};

export const calculateUnusualPremiumScore = (trade: Trade) => {
  if (!isUnusual(trade)) {
    return 0;
  }

  if (trade.premium < 80000) {
    return 1;
  } else if (trade.premium >= 80000 && trade.premium < 240000) {
    return 2;
  } else if (trade.premium >= 240000 && trade.premium < 440000) {
    return 3;
  } else if (trade.premium >= 440000 && trade.premium < 840000) {
    return 4;
  } else if (trade.premium >= 840000) {
    return 5;
  }

  return 0;
};

export const calculateStrongFlowScore = (putFlow: number) => {
  if (putFlow <= 2 || putFlow >= 98) {
    return 2;
  } else {
    return 0;
  }
};

export const calculateExpiryScore = (trade: Trade) => {
  const daysToExpiry = getDaysToExpiry(trade);

  if (daysToExpiry < 35) {
    return 3;
  } else if (daysToExpiry >= 35 && daysToExpiry < 99) {
    return 2;
  } else if (daysToExpiry >= 99) {
    return 1;
  }

  return 0;
};

export const calculateSweepScore = (trade: Trade) => {
  return isSweep(trade) ? 2 : 0;
};

export const calculateSizeScore = (trade: Trade) => {
  const size = trade.size || 0;
  if (size >= 10000) {
    return 2;
  } else if (size >= 5000 && size < 10000) {
    return 1;
  } else {
    return 0;
  }
};

export const calculateOtmScore = (trade: Trade) => {
  if (trade.otmPercent < 2) {
    return 0;
  } else if (trade.otmPercent >= 2 && trade.otmPercent < 8) {
    return 1;
  } else if (trade.otmPercent >= 8 && trade.otmPercent < 16) {
    return 2;
  } else if (trade.otmPercent >= 16 && trade.otmPercent < 22) {
    return 3;
  } else if (trade.otmPercent >= 22 && trade.otmPercent < 30) {
    return 4;
  } else if (trade.otmPercent >= 30) {
    return 5;
  }

  return 0;
};

export const calculatePriceScore = (trade: Trade) => {
  if (trade.price < 3) {
    return 3;
  } else if (trade.premium >= 3 && trade.price < 10) {
    return 1;
  } else if (trade.price >= 10) {
    return 0;
  }

  return 0;
};

export const calculateSizeOiScore = (trade: Trade) => {
  const sizeToOi = (trade.size || 0) / (trade.openInterest || 1);
  if (sizeToOi >= 5) {
    return 4;
  } else if (sizeToOi >= 2.5 && sizeToOi < 5) {
    return 3;
  } else if (sizeToOi >= 1.5 && sizeToOi < 2.5) {
    return 2;
  } else if (sizeToOi >= 0.75 && sizeToOi < 1.5) {
    return 1;
  } else {
    return 0;
  }
};

export const calculateVolumeScore = (trade: Trade) => {
  const sizeToVolume = (trade.size || 0) / (trade.avgContractsPerTrade || 1);
  if (sizeToVolume >= 10) {
    return 4;
  } else if (sizeToVolume >= 8 && sizeToVolume < 10) {
    return 3;
  } else if (sizeToVolume >= 6 && sizeToVolume < 8) {
    return 2;
  } else if (sizeToVolume >= 4 && sizeToVolume < 6) {
    return 1;
  } else {
    return 0;
  }
};

export const calculateOpenInterestScore = (trade: Trade) => {
  const oiAvg = (trade.openInterest || 0) / (trade.avgOpenInterestPerTrade || 1);
  if (oiAvg >= 10) {
    return 2;
  } else if (oiAvg >= 5 && oiAvg < 10) {
    return 1;
  } else {
    return 0;
  }
};

export const calculateImpliedVolScore = (_trade: Trade) => {
  return 0;

  // NOTE: Rethinking how implied vol should be viewed in the context of a score
  // const impliedVolAvg = (trade.impliedVol || 0) / (trade.avgImpliedVolPerTrade || 1);
  // if (impliedVolAvg >= 2.5) {
  //   return 2;
  // } else if (impliedVolAvg >= 1.5 && impliedVolAvg < 2.5) {
  //   return 1;
  // } else {
  //   return 0;
  // }
};

export const calculateHeatScore = (trade: Trade) => {
  const premiumScore = calculatePremiumScore(trade);
  // const unusualPremiumScore = calculateUnusualPremiumScore(trade);
  // const strongFlowScore = calculateStrongFlowScore(putFlow);
  const expiryScore = calculateExpiryScore(trade);
  const sweepScore = calculateSweepScore(trade);
  const sizeScore = calculateSizeScore(trade);
  const otmScore = calculateOtmScore(trade);
  // const priceScore = calculatePriceScore(trade);
  const sizeToOiScore = calculateSizeOiScore(trade);
  const volumeScore = calculateVolumeScore(trade);
  const oiScore = calculateOpenInterestScore(trade);
  const volatilityScore = calculateImpliedVolScore(trade);

  return (
    premiumScore +
    // unusualPremiumScore * UNUSUAL_SCORE_MULTIPLE +
    // strongFlowScore +
    expiryScore +
    sweepScore +
    sizeScore +
    otmScore * OTM_SCORE_MULTIPLE +
    // priceScore +
    sizeToOiScore +
    volumeScore +
    oiScore +
    volatilityScore
  );
};

export const calculateAggressiveScore = (trade: Trade) => {
  if (!isSweep(trade)) {
    return 0;
  }

  const premiumScore = calculatePremiumScore(trade);
  const expiryScore = calculateExpiryScore(trade);
  const otmScore = calculateOtmScore(trade);
  const oiScore = calculateSizeOiScore(trade);

  return premiumScore + expiryScore + otmScore + oiScore;
};
